import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 18,
  },
  path: {
    fill: '#00A0E6',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 74 38"
    >
      <path
        className={classes.path}
        d="M51.668 30.843c4.805 0 6.778-1.551 7.305-5.313l2.449-17.403h-4.959l-2.47 17.56c-.121.935-.69 1.341-1.828 1.341-1.138 0-1.625-.406-1.503-1.34l2.469-17.56h-4.969L45.794 25.53c-.527 3.82 1.177 5.313 5.874 5.313Zm-14.612-8.086L40 11.857h.42l-.81 10.9h-2.555Zm-6.857 7.558h4.814l1.07-3.956h3.259l-.294 3.956h4.887l.935-22.188h-8.16l-6.512 22.188Zm-15.029 0h4.563L21.269 19.4h.264l2.42 10.915h4.976l3.088-22.188h-4.562L25.842 19.62h-.26L23.206 8.127h-4.947L15.17 30.315Zm-15.17 0h4.969l1.352-9.697h.507l1.88 9.697h5.01l-2.276-11.737 5.039-10.45h-5.01L7.21 16.966h-.38L9.194 0h-5.01L0 30.315Zm64.704 7.04 2.44-17.611h4.835l.527-3.75H67.67l.56-4.117h5.243L74 8.127H63.789l-4.053 29.228h4.968Z"
      />
    </svg>
  );
};

export default LogoIcon;
