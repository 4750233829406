import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
// import { githubAuthApiRef } from '@backstage/core-plugin-api';
import { oktaAuthApiRef } from '@backstage/core-plugin-api';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { LearningPaths } from './components/learningPaths/LearningPaths'
// import { themes } from '@backstage/theme';
import { UnifiedThemeProvider } from '@backstage/theme';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { EntityValidationPage } from '@backstage-community/plugin-entity-validation';
import { VisitListener } from '@backstage/plugin-home';
import { KnaufTheme } from './theme/KnaufTheme';
import { EntitySnykContent } from 'backstage-plugin-snyk';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import { QetaPage } from '@drodil/backstage-plugin-qeta';
import { ToolboxPage } from '@drodil/backstage-plugin-toolbox';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { ShouldIDeployPage } from 'backstage-plugin-should-i-deploy';
import { AutoLogout } from '@backstage/core-components';

import { InfraWalletPage } from '@electrolux-oss/plugin-infrawallet';

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        // auto
        providers={[
          // 'guest',
          // {
          //   id: 'github-auth-provider',
          //   title: 'GitHub',
          //   message: 'Sign in using GitHub',
          //   apiRef: githubAuthApiRef,
          // },
          {
            id: 'okta-auth-provider',
            title: 'Okta',
            message: 'Sign in using Okta',
            apiRef: oktaAuthApiRef,
          }
        ]}
      />
    ),
  },
  themes: [
    {
      id: 'knauf-theme',
      title: 'Knauf Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={KnaufTheme} children={children} />
      ),
    },
    // {
    //   id: 'light',
    //   title: 'Light',
    //   variant: 'light',
    //   Provider: ({ children }) => (
    //     <UnifiedThemeProvider theme={themes.light} children={children} />
    //   ),
    // },
    // {
    //   id: 'dark',
    //   title: 'Dark',
    //   variant: 'dark',
    //   Provider: ({ children }) => (
    //     <UnifiedThemeProvider theme={themes.dark} children={children} />
    //   ),
    // },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="home" />} />
    <Route path="/home" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage initiallySelectedFilter="all" />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage initialFilter="all" />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/learning-paths" element={<LearningPaths />} />
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/qeta" element={<QetaPage title="Questions" />} />
    <Route path="/toolbox" element={<ToolboxPage />} />
    <Route path="/devtools" element={<DevToolsPage />} >
      {customDevToolsPage}
    </Route>
    <Route path="/catalog-unprocessed-entities" element={<CatalogUnprocessedEntitiesPage />}/>
    <Route path="/entity-validation" element={<EntityValidationPage />} />
    <Route path="/snyk" element={<EntitySnykContent />}/>
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/should-i-deploy" element={<ShouldIDeployPage />} />
    <Route path="/infrawallet" element={<InfraWalletPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AutoLogout
      idleTimeoutMinutes={360}
      useWorkerTimers={false}
      logoutIfDisconnected={false}
    />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
