import {
  createUnifiedTheme,
  createBaseThemeOptions,
  palettes,
} from '@backstage/theme';

export const KnaufTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      // Add custom colors here
      background: {
        default: '#f7f7f7',
        paper: '#fff',
      },
      primary: {
        main: '#0077c8',
        contrastText: '#fff',
      },
    },
  }),
  components: {
    // Add custom components here
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          padding: theme.spacing(3),
          boxShadow: '0 0 8px 3px rgba(20, 20, 20, 0.3)',
          backgroundImage: 'url("/knauf_og_4.png")',
        }),
      },
    },
    BackstageLogViewer: {
      styleOverrides: {
        root: {
          backgroundColor: '#F8F8FE',
          // color: '#ACB2BE',
        },
      },
    },
  },
});
