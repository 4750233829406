import {
  HomePageToolkit,
  HomePageStarredEntities,
  HomePageRandomJoke,
  WelcomeTitle,
  HomePageTopVisited,
  HomePageRecentlyVisited,
  HeaderWorldClock,
  ClockConfig,
  // CustomHomepageGrid,
} from '@backstage/plugin-home';
import { Content, Page, Header } from '@backstage/core-components';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { DevFriendsDayCard } from '@piatkiewicz/backstage-dev-friends-days';
import { XkcdComicCard } from 'backstage-plugin-xkcd';
import KnaufInfoCard from './KnaufInfoCard';
import LogoIcon from '../Root/LogoIcon';
import { ShouldIDeployCard } from 'backstage-plugin-should-i-deploy';
import { AnnouncementsCard } from '@procore-oss/backstage-plugin-announcements';
// import { ToolboxHomepageCard } from '@drodil/backstage-plugin-toolbox';

// Styles
const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const useTitleStyles = makeStyles({
  title: {
    fontSize: '50px',
    paddingTop: '30px',
    paddingBottom: '20px',
    color: '#00A0E6',
  },
});

const clockConfigs: ClockConfig[] = [
  {
    label: 'CET',
    timeZone: 'Europe/Berlin',
  },
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'STO',
    timeZone: 'Europe/Stockholm',
  },
  {
    label: 'NYC',
    timeZone: 'America/New_York',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const HomePage = () => {
  const classes = useStyles();
  const title = useTitleStyles();

  // Direct links to useful locations, which you can change to whatever you want
  const tools = [
    {
      url: '/catalog?filters%5Bkind%5D=component&filters%5Buser%5D=owned',
      label: 'Owned Services',
      icon: <LogoIcon />,
    },
    {
      url: '/catalog?filters%5Bkind%5D=component&filters%5Btype%5D=service&filters%5Buser%5D=all',
      label: 'Services',
      icon: <LogoIcon />,
    },
    {
      url: '/catalog?filters%5Bkind%5D=component&filters%5Btype%5D=website&filters%5Buser%5D=all',
      label: 'Web Sites',
      icon: <LogoIcon />,
    },
    {
      url: '/catalog?filters%5Bkind%5D=system&filters%5Buser%5D=all',
      label: 'Systems',
      icon: <LogoIcon />,
    },
    {
      url: '/catalog?filters%5Bkind%5D=user',
      label: 'Users',
      icon: <LogoIcon />,
    },
    {
      url: '/catalog?filters%5Bkind%5D=group',
      label: 'Groups',
      icon: <LogoIcon />,
    },
    {
      url: '/explore/tools',
      label: 'Explore Tools',
      icon: <LogoIcon />,
    },
    {
      url: '/tech-radar',
      label: 'Company Radar',
      icon: <LogoIcon />,
    },
    {
      url: '/docs',
      label: 'Docs',
      icon: <LogoIcon />,
    },
    {
      url: '/explore/groups',
      label: 'Organization Chart',
      icon: <LogoIcon />,
    },
    {
      url: '/create',
      label: 'Create New Component',
      icon: <LogoIcon />,
    },
    {
      url: 'https://github.com/Knauf-Group/cst-knauf-idp/issues',
      label: 'Submit New Issue',
      icon: <LogoIcon />,
    },
  ];

  // Use the search bar and starred entities as is
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title={<WelcomeTitle />} pageTitleOverride="Home">
          <HeaderWorldClock
            clockConfigs={clockConfigs}
            customTimeFormat={timeFormat}
          />
        </Header>
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <h1 className={title.title}>
              Knauf Digital - Internal Developer Platform
            </h1>

            <Grid container item xs={12} justifyContent="center" spacing={6}>
              <KnaufInfoCard />
            </Grid>

            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageTopVisited />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageRecentlyVisited />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <DevFriendsDayCard />
              </Grid>
              <Grid item md={6}>
                <AnnouncementsCard max={3} />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit tools={tools} />
              </Grid>
              <Grid item md={6} xs={12} >
                <XkcdComicCard showExplain={false} showNav={false} />
              </Grid>
              <Grid item>
                <HomePageRandomJoke />
              </Grid>
              <Grid item>
                <ShouldIDeployCard title='Should I deploy today?' timeZone="Europe/Berlin"/>
              </Grid>
            </Grid>
          </Grid>
          {/* <CustomHomepageGrid>
              <ToolboxHomepageCard />
          </CustomHomepageGrid> */}

        </Content>
      </Page>
    </SearchContextProvider>
  );
};
