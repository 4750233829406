import React from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { Content, Page, Header, InfoCard } from '@backstage/core-components';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
  },
  cardLink: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridItem: {
    maxWidth: 445, // Constraint maximum width of cards
    width: '100%',
  },
}));

export const LearningPaths = () => {
  const classes = useStyles();

  return (
    <Page themeId="home">
      <Header title="Learning Paths" subtitle="Your journey to mastering new skills" />
      <Content>
        <Box maxWidth="lg" mx="auto" px={0}> {/* Use Box to manage maxWidth and horizontal margins */}
          <Grid container spacing={4} justifyContent="flex-start" alignItems="flex-start" className={classes.gridContainer}>

            <Grid item xs={12} sm={12} md={12} lg={4} className={classes.gridItem}>
              <div className={classes.cardContainer}>
                <Link
                  to="/docs/default/component/cst-knauf-idp/onboarding/onboarding-existing-service"
                  className={classes.cardLink}
                >
                  <InfoCard title="How to onboard existing service to IDP" className={classes.infoCard}>
                    <Typography variant="body1" paragraph>
                      Ready to bring your existing service into the Knauf IDP ecosystem?
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Follow our streamlined onboarding process to ensure seamless integration and management. Our step-by-step guide will help you align your service with our standards, ensuring optimal performance and compliance.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      To get started, simply click on this card to begin the onboarding process. If you encounter any issues or need further assistance, please do not hesitate to reach out to our dedicated IDP team for support.
                    </Typography>
                  </InfoCard>
                </Link>
              </div>
            </Grid>

          </Grid>
        </Box>
      </Content>
    </Page>
  );
};