import * as React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export default function KnaufInfoCard() {
  return (
    <Card sx={{ maxWidth: 500 }}>
      <CardMedia
        sx={{ height: 250 }}
        image="/Buildonus.png"
        title="Build On Us"
      />
      <CardContent>
        <Typography align="center" paddingTop='10px' sx={{ mb: 2.5 }} gutterBottom variant="h5" component="div">
          Welcome to CST-IDP
        </Typography>
        <Typography align="justify" fontSize='16px' sx={{ mb: 1.5 }} variant="body2" color="black">
          Welcome to the Knauf Digital - Internal Developer Platform (IDP)! We're here to make your life as a developer easier and more productive.
        </Typography>
        <Typography align="justify" fontSize='16px' sx={{ mb: 0.5 }} variant="body2" color="black">
          IDP is a platform built on Kubernetes that lets you onboard, deploy, and manage your applications all by yourself. No need to dive into the complexities of infrastructure.
        </Typography>
        <Typography align="justify" fontSize='16px' sx={{ mb: 0.5 }} variant="body2" color="black">
          With IDP’s simple and intuitive interface, deploying your applications is as easy as a few clicks. So, you can spend less time troubleshooting and more time coding and innovating.
        </Typography>
        <Typography align="justify" fontSize='16px' sx={{ mb: 0.5 }} variant="body2" color="black">
          Let’s make deployment smooth and hassle-free. Welcome to IDP!
        </Typography>
      </CardContent>
      <CardActions>
        <Button component={Link} to="/docs/default/component/cst-knauf-idp" size="large" >Learn More</Button>
      </CardActions>
    </Card>
  );
}
